import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import AppRedirects from './AppRedirects';

function delegateToEmber(): null {
  const emberUrl = window.location.href.replace('/app/boot/', '/old-dashboard/');
  window.location.replace(emberUrl);
  return null;
}

export default class Shell extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          component={() => (
            <>
              <AppRedirects />
              <Dashboard />
            </>
          )}
        />
        <Route match="*" children={delegateToEmber} />
      </Switch>
    );
  }
}
